import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Modal from "../components/modal";
import { appUrl } from '../helpers/helpers'

import { ddp } from "../api/ddp"
import './marketing-stock.less';

const fileUrl = (file) => {
  return `${appUrl}/s3/marketingStock/${file.name}`;
};
const previewUrl = (preview) => {
  const name = preview && preview.name;
  if (name) {
    return `${appUrl}/s3/marketingStock/${name}`;
  } else {
    return `${appUrl}/img/preview.png`;
  }
};
const imageUrl = (image) => {
  let name;
  if (((image.preview && image.preview.type) || "").startsWith("image/")) {
    name = image.preview.name;
  } else if (((image.file && image.file.type) || "").startsWith("image/")) {
    name = image.file.name;
  }
  if (name) {
    return `"${appUrl}/s3/marketingStock/${name}"`;
  } else {
    return `"${appUrl}/img/preview.png"`;
  }
};

const MarketingStockLogosSection = ({ marketingFiles }) => {
  const logos = marketingFiles.filter(file => file.section === "logos");

  return (
    <div className="MarketingStockLogosSection">
      {logos.map((logo, idx) => (
        <div key={`logo-${idx}`} className="MarketingStockLogo">
          <div className="MarketingStockLogo__text">
            <h6>{logo.name}</h6>
            <p>{logo.description}</p>
            <p>
              <a href={fileUrl(logo.file)} target="_blank" download={logo.file.filename}>
                <i className="fa fa-download" aria-hidden="true"></i>Download
              </a>
            </p>
            <small className="MarketingStockLogo__disclaimer">Download requires following company and brand guidelines.</small>
          </div>
          <div className="MarketingStockLogo__preview">
            <img src={previewUrl(logo.preview)} alt={logo.name} />
          </div>
        </div>
      ))}
    </div>
  );
};

const MarketingStockImage = ({ image }) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className="MarketingStockImage">
      <div className="MarketingStockImage__preview" style={{ backgroundImage: `url(${imageUrl(image)})` }} onClick={() => { setModalOpen(true) }}>
        <div className="MarketingStockImage__label">{image.name}</div>
      </div>
      {modalOpen ? <Modal onClose={() => setModalOpen(false)}>
        <div className="MarketingStockImageModal">
          <div className="MarketingStockImageModal__left" style={{ backgroundImage: `url(${imageUrl(image)})` }}></div>
          <div className="MarketingStockImageModal__right">
            <div className="MarketingStockImageModal__text">
              <h6>{image.name}</h6>
              <p>{image.description}</p>
              <p>
                <a href={fileUrl(image.file)} target="_blank" download={image.file.filename}>
                  <i className="fa fa-download" aria-hidden="true"></i>Download
                </a>
              </p>
              <small className="MarketingStockImageModal__disclaimer">Download requires following company and brand guidelines.</small>
            </div>
          </div>
        </div>
      </Modal> : null}
    </div>
  );
};

const MarketingStockImagesSection = ({ marketingFiles, section = 'photos' }) => {
  const images = marketingFiles.filter(file => file.section === section);

  return (
    <div className="MarketingStockImagesSection">
      {images.map((image, idx) => <MarketingStockImage key={`img-${idx}`} image={image} />)}
    </div>
  );
};

const MarketingStockVideosSection = ({ marketingFiles }) => {
  const videos = marketingFiles.filter(file => file.section === "videos");

  return (
    <div className="MarketingStockVideosSection">
      {videos.map((video, idx) => (
        <div key={`video-${idx}`} className="MarketingStockVideo">
          <div className="MarketingStockVideo__preview" style={{ backgroundImage: `url(${previewUrl(video.preview)})` }}>
            <div className="MarketingStockVideo__label">{video.name}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const MarketingStockPage = () => {
  const [marketingFiles, setMarketingFiles] = useState([]);

  useEffect(() => {
    const getMarketingFilesMethodId = ddp.method("marketingFiles/get", [{}]);
    ddp.on("result", message => {
      if (message.id === getMarketingFilesMethodId && !message.error && message.result?.length) {
        setMarketingFiles(message.result);
      }
    })
  }, [marketingFiles.length]);

  return (
    <Layout>
      <SEO title="Marketing Stock" />
      <div id="MarketingStock" className="MarketingStock">
        <div className="container">
          <h4>Marketing Stock</h4>
          <h5>Logos</h5>
          <MarketingStockLogosSection marketingFiles={marketingFiles} />
          <div className="MarketingStock_divider"></div>
          <h5>Banners</h5>
          <MarketingStockImagesSection marketingFiles={marketingFiles} section='banners' />
          <div className="MarketingStock_divider"></div>
          <h5>Photo and Video Gallery</h5>
          <MarketingStockImagesSection marketingFiles={marketingFiles} section='photos' />
          <MarketingStockVideosSection marketingFiles={marketingFiles} />
          <div className="MarketingStock_divider"></div>
          <h5>In-App Screenshots</h5>
          <MarketingStockImagesSection marketingFiles={marketingFiles} section='screenshots' />
        </div>
      </div>
    </Layout>
  )
}

export default MarketingStockPage
